export const CATEGORIES = {
  KARMA: 'Karma',
  REFERRAL: 'Referral',
  LOGIN: 'Login',
  MOBILE_EXTENSION: 'Mobile Extension',
  CLICK: 'Click',
};

export const PAGES = {
  ME: '/my-account',
  MY_ACCOUNT: '/my-account',
  LOGIN: '/login',
  SAFARI_MOBILE_EXTENSION: 'Safari Mobile Extension',
};

export const TRACKING_EVENTS = {
  REFERRAL_CLICKED_REFER_SHARE: 'Clicked Refer Share',
  REFERRAL_CLICKED_SEND_REFER: 'Clicked Send Refer',
  REFERRAL_CLICKED_REFER_SIGNUP: 'Clicked Refer Signup',
  CLICKED_INVITE_FRIENDS: 'clicked_invite_friends',
  CLICKED_EXTENSION: 'Clicked Get Extension',
  CLICKED_SEND_ME_EXTENSION: 'Clicked Send Me Desktop App',
  SENT_APP_TEXT: 'Successfully Sent App Text',
  COPY_CODE: 'copy_code',
  GET_EXTENSION: 'get_extension_click',
  SEND_EXTENSION_DESKTOP: 'clicked_send_me_desktop_app',
  ACTIVATE_REWARDS: 'activate_rewards_click',
  CLICKED_BREADCRUMB: 'breadcrumb_click',
  CLICKED_LOGIN: 'Clicked Email Signup or Login CTA',
  CLICKED_PRESEARCH: 'pre_search_click',
  SEARCH_STARTED: 'search_started',
  SEARCH: 'search',
  NEWSLETTER_SIGNUP: 'Clicked Newsletter Signup',
  NEWSLETTER_SIGNUP_GA: 'newsletter_signup',
  NEWSLETTER_DEAL: 'Clicked Newsletter Deal',
  CLICKED_MDP: 'Clicked MDP',
  CLICKED_MDP_GA: 'mdp_click',
  CLICKED_CHATGPT_PLUGIN_GA: 'chatgpt_plugin_click',
  CLICKED_CHATGPT_TRY_NOW: 'Clicked ChatGPT Try Now',
  CLICKED_LEARN_MORE_GA: 'learn_more_click',
  CLICKED_LEARN_MORE: 'Clicked Learn More',
  CLICKED_REWARDS_HISTORY: 'Clicked Rewards - History',
  CLICKED_REWARDS_REDEEM: 'Clicked Rewards - Redeem',
  VIEWED_UNINSTALL_PAGE: 'Viewed Uninstall Page',
  EXTENSION_UNINSTALL: 'Extension Uninstalled',
  CLICKED_CONTINUE_LOGIN: 'Clicked Continue Login or Signup',
  VIEW_LOGIN: 'Viewed Login or Signup Code Page',
  VIEW_VERIFY_CODE: 'Viewed Verify Code Page',
  SIGN_UP: 'sign_up',
  VOTE_CODE: 'vote_code',
  VOTE_DOWNVOTE_REASON: 'vote_downvote_reason',
  VOTE_SU_CODE: 'vote_su_code',
  CLICKED_MY_KARMA: 'Clicked View My Karma',
  CLICKED_ON_MISSION: 'Clicked on Mission',
  CLICKED_VIEW_REWARDS: 'Clicked View Rewards',
  VIEWED_ME_PAGE: 'Viewed /me Page',
  SMS_SENT: 'Successfully Sent App Text',
  VIEWED_SMS_PAGE: 'Viewed Text me the app',
  VIEWED_MOBILE_EXTENSION_PAGE: 'Viewed Mobile Extension Page',
  VIEWED_MY_ACCOUNT: 'Viewed My Account Page',
  VIEWED_REFER_PAGE: 'Viewed Refer Page',
  VIEWED_REFERER_SIGNUP_PAGE: 'Viewed Refer Signup Page',
  REFERRAL_VIEW_INSTALL_PAGE: 'View Referral Install Page',
  CLICKED_HOUSE_AD: 'Clicked house ad',
  VIEWED_HOUSE_AD: 'Viewed house ad',
  CLICKED_PRODUCT: 'Clicked a Product',
  SEARCH_COMPLETE: 'search_complete',
  NO_CODE_EXPERIENCE: 'no_code_experience',
  TAB_CLICKED: 'tab_clicked',
  LEAVE_REVIEW_CLICKED: 'leave_review_clicked',

  // GACHA
  VIEWED_GACHA_MODAL: 'Opened to play game',
  CLOSED_GACHA_MODAL: 'closed to play game',
  CLICKED_OBTAIN_BAG: 'Clicked to obtain a bag',
  CLICKED_OPEN_BAG: 'Clicked to open bag',
  CLICKED_CLAIM_REWARD: 'Clicked claim reward',

  SHOP_CLICK_TO_MERCHANT: 'shop_click_to_merchant',
  // Page View events
  VIEW_MERCHANT_PAGE: 'view_merchant_page',
  // Savings Tips
  ADD_COMMENT_CLICKED: 'add_comment_clicked',
  ADD_COMMENT: 'add_comment',
  VOTE_COMMENT: 'vote_comment',
  LOGIN_TO_COMMENT: 'login_to_comment',
  LOGIN_TO_VOTE: 'login_to_vote',
  LOAD_MORE_COMMENTS: 'load_more_comments',
  DELETE_COMMENT: 'delete_comment',
  EDIT_COMMENT: 'edit_comment',
  EXPAND_ACTIVITY: 'savings_tip_code_activity',
  EXPAND_TIPS: 'savings_tip_expand_comments',
};

export const MCP_PAGE_SECTIONS = {
  CAREER_STATUS_DISCOUNTS: 'career-status-discounts-section',
  COMMUNITY_ACTIVITY: 'community-activity-section',
  PRICE_DROPS: 'price-drops-section',
  RESELLER_CODES: 'reseller-section',
  SAVINGS_TIPS: 'savings_tips-section',
  STATS_AND_OFFERS: 'stats-and-offers-section',
  SIDEBAR: 'sidebar-section',
  SIMILAR_STORE_CODES: 'similar-merchants-section',
  SINGLE_USE_CODES: 'single-use-codes-section',
  BEST_DEALS: 'best-deal-section',
  UNVERIFIED_CODES: 'fallback-promos-section',
  VERIFIED_CODES: 'verified-promos-section',
  FORECAST: 'forecast-section',
  POST_CLICK_MODAL: 'reveal-code-modal',
  REVIEWS: 'reviews',
  ALL_CODES_MODAL: 'all-codes-modal',
  UNVERIFIED_CODES_MODAL: 'unverified-codes-modal',
};

export const SHARING_OPTIONS = {
  COPY: 'copy',
  EMAIL: 'email',
  FACEBOOK: 'fb',
  FACEBOOK_MESSENGER: 'fb_messenger',
  SMS: 'sms',
  TWITTER: 'twitter',
  OTHERS: 'more_options',
};

export const ANALYTICS_PAGES = {
  HOME: 'Home',
  VIEWED_HOME: 'Viewed Home Page',
  MOBILE_EXTENSION: 'Safari Mobile Extension',
  LOGIN: 'Login',
  TEXT_ME: 'Viewed Text me the app',
  SUBMIT_COUPON: 'Viewed Submit a Coupon Page',
  EVENTS: 'Viewed Event Landing Page',
  PLAY: 'Viewed Play Page',
  EXPLORE: 'Viewed Explore Page',
} as const;

export const ANALYTICS_CATEGORIES = {
  EXTERNAL: 'External',
  MOBILE_EXTENSION: 'Mobile Extension',
  LOGIN: 'Login',
  HOME: 'Home',
  EVENTS_LANDING_PAGE: 'Events Landing Page',
};

export const GA_EVENT = {
  INPUT_FIELD_CLICK: 'input_field_click',
  INPUT_FIELD_ERROR: 'input_error_message',
  PAGE_VIEW: 'page_view',
  SIGN_UP: 'sign_up',
} as const;

export const GA_INPUT = {
  OPTIONS: 'option_select',
  FILE: 'file_input',
  ORDER_AMOUNT: 'order_amount_input',
  DATEPICKER: 'datepicker_input',
  MERCHANT: 'merchant_input',
} as const;
