/*
 * Browser-specific extension download URLs and IDs
 * TODO: Use shared-utils
 */

export const QR_IMGS = {
  local: 'rebrand_qr_local.jpg',
  dev: 'rebrand_qr_dev.jpg',
  qa: 'rebrand_qr_qa.jpg',
  prod: 'rebrand_qr_prod.jpg',
};
export const qrText = 'Get the free app for iPhone or Android';

export const VALID_BROWSERS = {
  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  MOBILE_SAFARI: 'Mobile Safari',
} as const;
export type ValidBrowserType = (typeof VALID_BROWSERS)[keyof typeof VALID_BROWSERS];
export function isValidBrowser(browser: string): browser is ValidBrowserType {
  if (!browser)
    return false;

  // adding the type cast here because TS if not forces the type on the incoming argument
  const browsers = Object.values(VALID_BROWSERS).map(b => b.toLowerCase());
  return browsers.includes(browser.toLowerCase());
}

export const RELEVANT_OS = {
  IOS: 'iOS',
  ANDROID: 'Android',
} as const;
export type RelevantOsType = (typeof RELEVANT_OS)[keyof typeof RELEVANT_OS];
export function isValidOS(os: string): os is RelevantOsType {
  if (!os)
    return false;

  const oses = Object.values(RELEVANT_OS).map(o => o.toLowerCase());
  return oses.includes(os.toLowerCase());
}

export const VALID_CAMPAIGNS = {
  FOOTER: 'footer',
  HOUSE_ADS: 'house_ads',
  REBRAND_ANNOUNCEMENT: 'rebrand_announcement',
  WELCOME_SERIES_INAUGURAL: 'welcome_series_inaugural',
  CONTRIBUTOR_ANNOUNCEMENT: 'contributor_announcement',
  INSTAGRAM_ALWAYS_ON: 'instagram_always_on',
  WELCOME_SERIES_TOKENS: 'welcome_series_tokens',
  SAVINGS_TIPS_ANNOUNCEMENT: 'savings_tips_announcement',
} as const;
export type ValidCampaignType = (typeof VALID_CAMPAIGNS)[keyof typeof VALID_CAMPAIGNS];
export function isValidCampaign(campaign: string): campaign is ValidCampaignType {
  return Object.values(VALID_CAMPAIGNS).includes(campaign as ValidCampaignType);
}

export type PlatformKey = RelevantOsType | ValidBrowserType;

interface CampaignParams {
  source: string;
  medium: string;
  campaign: string;
  apple_campaign: string; // Variant campaign name for App Store Connect (because it doesn't capture source/medium)
}
type CampaignParamKeys = keyof CampaignParams;

interface PlatformTrackingConfig {
  param: string;
  value?: string | number;
  from?: CampaignParamKeys;
  join?: { param: string; value?: string | number; from?: CampaignParamKeys }[];
}
const PLATFORM_TRACKING_DEFAULT: PlatformTrackingConfig[] = [
  { param: 'utm_source', from: 'source' },
  { param: 'utm_medium', from: 'medium' },
  { param: 'utm_campaign', from: 'campaign' },
];
const PLATFORM_TRACKING_APPLE: PlatformTrackingConfig[] = [
  { param: 'ct', from: 'apple_campaign' },
  { param: 'pt', value: 2106994 },
  { param: 'mt', value: 8 },
];
const PLATFORM_TRACKING_ANDROID: PlatformTrackingConfig[] = [
  {
    param: 'referrer',
    join: [
      { param: 'utm_source', from: 'source' },
      { param: 'utm_medium', from: 'medium' },
      { param: 'utm_campaign', from: 'campaign' },
    ],
  },
];
const PLATFORM_TRACKING: Record<PlatformKey, PlatformTrackingConfig[]> = {
  [VALID_BROWSERS.CHROME]: PLATFORM_TRACKING_DEFAULT,
  [VALID_BROWSERS.EDGE]: PLATFORM_TRACKING_DEFAULT,
  [VALID_BROWSERS.FIREFOX]: PLATFORM_TRACKING_DEFAULT,
  [VALID_BROWSERS.SAFARI]: PLATFORM_TRACKING_APPLE,
  [VALID_BROWSERS.MOBILE_SAFARI]: PLATFORM_TRACKING_APPLE,
  [RELEVANT_OS.IOS]: PLATFORM_TRACKING_APPLE,
  [RELEVANT_OS.ANDROID]: PLATFORM_TRACKING_ANDROID,
};

const CAMPAIGN_TRACKING: Record<ValidCampaignType, CampaignParams> = {
  [VALID_CAMPAIGNS.FOOTER]: {
    source: 'simplycodes.com',
    medium: 'website_button',
    campaign: 'footer',
    apple_campaign: 'simplycodes_footer',
  },
  [VALID_CAMPAIGNS.HOUSE_ADS]: {
    source: 'simplycodes.com',
    medium: 'website_banner',
    campaign: 'house_ads',
    apple_campaign: 'simplycodes_house_ads',
  },
  [VALID_CAMPAIGNS.REBRAND_ANNOUNCEMENT]: {
    source: 'bulk_email',
    medium: 'email_button',
    campaign: 'rebrand_announcement',
    apple_campaign: 'rebrand_announcement',
  },
  [VALID_CAMPAIGNS.WELCOME_SERIES_INAUGURAL]: {
    source: 'campaign_monitor',
    medium: 'email_button',
    campaign: 'welcome_series_inaugural',
    apple_campaign: 'welcome_series_inaugural',
  },
  [VALID_CAMPAIGNS.CONTRIBUTOR_ANNOUNCEMENT]: {
    source: 'campaign_monitor',
    medium: 'email_button',
    campaign: 'contributor_announcement',
    apple_campaign: 'contributor_announcement',
  },
  [VALID_CAMPAIGNS.INSTAGRAM_ALWAYS_ON]: {
    source: 'instagram',
    medium: 'social',
    campaign: 'instagram_always_on',
    apple_campaign: 'instagram_always_on',
  },
  [VALID_CAMPAIGNS.WELCOME_SERIES_TOKENS]: {
    source: 'campaign_monitor',
    medium: 'email_button',
    campaign: 'welcome_series_tokens',
    apple_campaign: 'welcome_series_tokens',
  },
  [VALID_CAMPAIGNS.SAVINGS_TIPS_ANNOUNCEMENT]: {
    source: 'campaign_monitor',
    medium: 'email_button',
    campaign: 'savings_tips_announcement',
    apple_campaign: 'savings_tips_announcement',
  },
};

interface TrackingInfo {
  href: string;
  fullHref: string;
}
export type getSimplyCodesLinkOptions = {
  campaign?: ValidCampaignType;
} & (
  | {
    browser: ValidBrowserType;
    os?: never;
  }
  | {
    browser?: never;
    os: RelevantOsType;
  }
);

export const EXT_CHROME
  = 'https://chrome.google.com/webstore/detail/simplycodes-coupons-codes/gfkpklgmocbcbdabfellcnikamdaeajd';
export const EXT_CHROME_ALT
  = 'https://chromewebstore.google.com/detail/simplycodes-coupons-codes/gfkpklgmocbcbdabfellcnikamdaeajd';
export const EXT_CHROME_APP_ID = 'gfkpklgmocbcbdabfellcnikamdaeajd';

export const EXT_EDGE
  = 'https://microsoftedge.microsoft.com/addons/detail/simplycodes-coupons-code/onkfibnhmkfjacdcodpdfdlmnhjleafm';
export const EXT_EDGE_APP_ID = 'onkfibnhmkfjacdcodpdfdlmnhjleafm';

export const EXT_FIREFOX = 'https://addons.mozilla.org/en-US/firefox/addon/simplycodes/';

export const EXT_MACOS_IOS = 'https://apps.apple.com/app/apple-store/id1538885494';

export const EXT_SAFARI_APP_ID = '1538885494';

export const EXT_ANDROID = 'https://play.google.com/store/apps/details?id=com.simplycodes.mobile';

function getPlatformHref(platformKey: PlatformKey) {
  switch (platformKey) {
    case 'iOS':
      return EXT_MACOS_IOS;
    case 'Android':
      return EXT_ANDROID;
    case 'Edge':
      return EXT_EDGE;
    case 'Firefox':
      return EXT_FIREFOX;
    case 'Safari':
      return EXT_MACOS_IOS;
    case 'Mobile Safari':
      return EXT_MACOS_IOS;
    case 'Chrome':
      return EXT_CHROME;
    default:
      return EXT_CHROME;
  }
}

function attachCampaignTrackingParams(url: URL, campaign: ValidCampaignType, platformKey: PlatformKey) {
  const campaignParams = CAMPAIGN_TRACKING[campaign];
  const platformConfig = PLATFORM_TRACKING[platformKey];

  const params = url.searchParams;
  for (const { param, join, from, value } of platformConfig) {
    if (join) {
      // Special handling for platforms like Android
      const specialParams = new URLSearchParams();
      for (const { param: joinParam, from: joinFrom, value: joinValue } of join) {
        if (joinFrom && campaignParams[joinFrom])
          specialParams.set(joinParam, String(campaignParams[joinFrom]));
        else if (joinValue)
          specialParams.set(joinParam, String(joinValue));
      }
      params.set(param, specialParams.toString());
    } else if (from && campaignParams[from]) {
      // Map a parameter from a known key
      params.set(param, String(campaignParams[from]));
    } else if (value) {
      // Set parameter to a static value
      params.set(param, String(value));
    }
  }
}

const DEFAULT_PLATFORM = VALID_BROWSERS.CHROME;

export function getExtensionTrackingInfo(options: getSimplyCodesLinkOptions): TrackingInfo {
  const platformKey = options.os || options.browser || DEFAULT_PLATFORM;

  const href = getPlatformHref(platformKey);
  let fullHref;
  try {
    fullHref = new URL(href);
  } catch (error) {
    console.error('Error creating URL', error);
    fullHref = new URL('https://chrome.google.com/webstore/detail/simplycodes-coupons-codes/gfkpklgmocbcbdabfellcnikamdaeajd');
  }
  if (options.campaign)
    attachCampaignTrackingParams(fullHref, options.campaign, platformKey);

  return {
    href,
    fullHref: fullHref.toString(),
  };
}
