import { default as abouteZMWKFsTTEMeta } from "/usr/src/app/src/pages/about.vue?macro=true";
import { default as best_45coupon_45app_45installnowafWV8oQMutMeta } from "/usr/src/app/src/pages/best-coupon-app-installnow.vue?macro=true";
import { default as _91slug_93vMTBwxXXrIMeta } from "/usr/src/app/src/pages/blog/[slug].vue?macro=true";
import { default as indextMG73B3BqYMeta } from "/usr/src/app/src/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46category_93SFi0qCRaLwMeta } from "/usr/src/app/src/pages/category/[...category].vue?macro=true";
import { default as index29FTbaYTWWMeta } from "/usr/src/app/src/pages/category/index.vue?macro=true";
import { default as communitiesr4A362XrYRMeta } from "/usr/src/app/src/pages/communities.vue?macro=true";
import { default as earnsmdas3Jk3MMeta } from "/usr/src/app/src/pages/earn.vue?macro=true";
import { default as explore7jqahl8YgcMeta } from "/usr/src/app/src/pages/explore.vue?macro=true";
import { default as _91method_93293yUKBMywMeta } from "/usr/src/app/src/pages/extension/login/[method].vue?macro=true";
import { default as feedbackPD8h1mfagpMeta } from "/usr/src/app/src/pages/feedback.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as _91browser_93zjIskVlyOaMeta } from "/usr/src/app/src/pages/installed/[browser].vue?macro=true";
import { default as indexNPkinD7MjDMeta } from "/usr/src/app/src/pages/installed/safari-ios/index.vue?macro=true";
import { default as permissions_45workaroundAoqEoOHcuuMeta } from "/usr/src/app/src/pages/installed/safari-ios/permissions-workaround.vue?macro=true";
import { default as permissionsISDg8P2vKHMeta } from "/usr/src/app/src/pages/installed/safari-ios/permissions.vue?macro=true";
import { default as _91code_93lRwo6OiUn1Meta } from "/usr/src/app/src/pages/invite/[code].vue?macro=true";
import { default as indexmEEaIPZ0XhMeta } from "/usr/src/app/src/pages/invite/index.vue?macro=true";
import { default as jetsetterqmIi3N6P9kMeta } from "/usr/src/app/src/pages/jetsetter.vue?macro=true";
import { default as login2t1QMQfxAxMeta } from "/usr/src/app/src/pages/login.vue?macro=true";
import { default as missing_45karmatfZAiHjuK2Meta } from "/usr/src/app/src/pages/missing-karma.vue?macro=true";
import { default as edit_45profileDeIwvBJr14Meta } from "/usr/src/app/src/pages/my-account/edit-profile.vue?macro=true";
import { default as cashed_45outqHc8FTxnL0Meta } from "/usr/src/app/src/pages/my-account/history/cashed-out.vue?macro=true";
import { default as earnedjzbgldJVXdMeta } from "/usr/src/app/src/pages/my-account/history/earned.vue?macro=true";
import { default as indexKV7ohIk5mLMeta } from "/usr/src/app/src/pages/my-account/history/index.vue?macro=true";
import { default as redeemed16cOb7XVvYMeta } from "/usr/src/app/src/pages/my-account/history/redeemed.vue?macro=true";
import { default as historyDK506raN5OMeta } from "/usr/src/app/src/pages/my-account/history.vue?macro=true";
import { default as index1iqJseCa1vMeta } from "/usr/src/app/src/pages/my-account/index.vue?macro=true";
import { default as redeem_45cashDK1ErEJLkDMeta } from "/usr/src/app/src/pages/my-account/redeem-cash.vue?macro=true";
import { default as account_45info6qwd9GchlwMeta } from "/usr/src/app/src/pages/my-account/settings/account-info.vue?macro=true";
import { default as indexPXV6E4bOnHMeta } from "/usr/src/app/src/pages/my-account/settings/index.vue?macro=true";
import { default as login_45securityzkMV5j28rpMeta } from "/usr/src/app/src/pages/my-account/settings/login-security.vue?macro=true";
import { default as notificationsB8hRZNoUphMeta } from "/usr/src/app/src/pages/my-account/settings/notifications.vue?macro=true";
import { default as personal_45infoZSRlpqkuy3Meta } from "/usr/src/app/src/pages/my-account/settings/personal-info.vue?macro=true";
import { default as privacyQI4TTqpaw5Meta } from "/usr/src/app/src/pages/my-account/settings/privacy.vue?macro=true";
import { default as my_45accountni7IwvplOnMeta } from "/usr/src/app/src/pages/my-account.vue?macro=true";
import { default as notify_45consentAj2LHi34XIMeta } from "/usr/src/app/src/pages/notify-consent.vue?macro=true";
import { default as playPZXwj3CFZkMeta } from "/usr/src/app/src/pages/play.vue?macro=true";
import { default as indexYuIo5KMcuNMeta } from "/usr/src/app/src/pages/privacy/index.vue?macro=true";
import { default as privacy_45policy19BsNAus8IMeta } from "/usr/src/app/src/pages/privacy/privacy-policy.vue?macro=true";
import { default as _91slug_93xxGejvA0zGMeta } from "/usr/src/app/src/pages/shop/[slug].vue?macro=true";
import { default as simplycodes_45mobile_45extensionXauyZR8fyEMeta } from "/usr/src/app/src/pages/simplycodes-mobile-extension.vue?macro=true";
import { default as smartshoppero2W8Efc6qmMeta } from "/usr/src/app/src/pages/smartshopper.vue?macro=true";
import { default as couponsiKQJga7d1XMeta } from "/usr/src/app/src/pages/store/[merchant]/coupons.vue?macro=true";
import { default as _91merchant_93fN5PuGXriZMeta } from "/usr/src/app/src/pages/store/[merchant].vue?macro=true";
import { default as _91_91merchant_93_93w8dYjFdTPvMeta } from "/usr/src/app/src/pages/submit-coupon/[[merchant]].vue?macro=true";
import { default as termsA1HF1pYOfSMeta } from "/usr/src/app/src/pages/terms.vue?macro=true";
import { default as uninstallJY9fJhHYptMeta } from "/usr/src/app/src/pages/uninstall.vue?macro=true";
import { default as verify_45codeku7f4YFtPYMeta } from "/usr/src/app/src/pages/verify-code.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: abouteZMWKFsTTEMeta || {},
    component: () => import("/usr/src/app/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "best-coupon-app-installnow",
    path: "/best-coupon-app-installnow",
    meta: best_45coupon_45app_45installnowafWV8oQMutMeta || {},
    component: () => import("/usr/src/app/src/pages/best-coupon-app-installnow.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93vMTBwxXXrIMeta || {},
    component: () => import("/usr/src/app/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indextMG73B3BqYMeta || {},
    component: () => import("/usr/src/app/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "category-category",
    path: "/category/:category(.*)*",
    meta: _91_46_46_46category_93SFi0qCRaLwMeta || {},
    component: () => import("/usr/src/app/src/pages/category/[...category].vue").then(m => m.default || m)
  },
  {
    name: "category",
    path: "/category",
    meta: index29FTbaYTWWMeta || {},
    component: () => import("/usr/src/app/src/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "communities",
    path: "/communities",
    meta: communitiesr4A362XrYRMeta || {},
    component: () => import("/usr/src/app/src/pages/communities.vue").then(m => m.default || m)
  },
  {
    name: "earn",
    path: "/earn",
    meta: earnsmdas3Jk3MMeta || {},
    component: () => import("/usr/src/app/src/pages/earn.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    meta: explore7jqahl8YgcMeta || {},
    component: () => import("/usr/src/app/src/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "extension-login-method",
    path: "/extension/login/:method()",
    meta: _91method_93293yUKBMywMeta || {},
    component: () => import("/usr/src/app/src/pages/extension/login/[method].vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: feedbackPD8h1mfagpMeta || {},
    component: () => import("/usr/src/app/src/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexYu3DynwDClMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "installed-browser",
    path: "/installed/:browser()",
    meta: _91browser_93zjIskVlyOaMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/[browser].vue").then(m => m.default || m)
  },
  {
    name: "installed-safari-ios",
    path: "/installed/safari-ios",
    meta: indexNPkinD7MjDMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/index.vue").then(m => m.default || m)
  },
  {
    name: "installed-safari-ios-permissions-workaround",
    path: "/installed/safari-ios/permissions-workaround",
    meta: permissions_45workaroundAoqEoOHcuuMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/permissions-workaround.vue").then(m => m.default || m)
  },
  {
    name: "installed-safari-ios-permissions",
    path: "/installed/safari-ios/permissions",
    meta: permissionsISDg8P2vKHMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/permissions.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    meta: _91code_93lRwo6OiUn1Meta || {},
    component: () => import("/usr/src/app/src/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: indexmEEaIPZ0XhMeta || {},
    component: () => import("/usr/src/app/src/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: "jetsetter",
    path: "/jetsetter",
    meta: jetsetterqmIi3N6P9kMeta || {},
    component: () => import("/usr/src/app/src/pages/jetsetter.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login2t1QMQfxAxMeta || {},
    component: () => import("/usr/src/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "missing-karma",
    path: "/missing-karma",
    meta: missing_45karmatfZAiHjuK2Meta || {},
    component: () => import("/usr/src/app/src/pages/missing-karma.vue").then(m => m.default || m)
  },
  {
    name: my_45accountni7IwvplOnMeta?.name,
    path: "/my-account",
    meta: my_45accountni7IwvplOnMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-edit-profile",
    path: "edit-profile",
    meta: edit_45profileDeIwvBJr14Meta || {},
    component: () => import("/usr/src/app/src/pages/my-account/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: historyDK506raN5OMeta?.name,
    path: "history",
    meta: historyDK506raN5OMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-history-cashed-out",
    path: "cashed-out",
    meta: cashed_45outqHc8FTxnL0Meta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/cashed-out.vue").then(m => m.default || m)
  },
  {
    name: "my-account-history-earned",
    path: "earned",
    meta: earnedjzbgldJVXdMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/earned.vue").then(m => m.default || m)
  },
  {
    name: "my-account-history",
    path: "",
    meta: indexKV7ohIk5mLMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-history-redeemed",
    path: "redeemed",
    meta: redeemed16cOb7XVvYMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/redeemed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "my-account",
    path: "",
    meta: index1iqJseCa1vMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-redeem-cash",
    path: "redeem-cash",
    meta: redeem_45cashDK1ErEJLkDMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/redeem-cash.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-account-info",
    path: "settings/account-info",
    meta: account_45info6qwd9GchlwMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/account-info.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings",
    path: "settings",
    meta: indexPXV6E4bOnHMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-login-security",
    path: "settings/login-security",
    meta: login_45securityzkMV5j28rpMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/login-security.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-notifications",
    path: "settings/notifications",
    meta: notificationsB8hRZNoUphMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-personal-info",
    path: "settings/personal-info",
    component: () => import("/usr/src/app/src/pages/my-account/settings/personal-info.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-privacy",
    path: "settings/privacy",
    meta: privacyQI4TTqpaw5Meta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/privacy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "notify-consent",
    path: "/notify-consent",
    meta: notify_45consentAj2LHi34XIMeta || {},
    component: () => import("/usr/src/app/src/pages/notify-consent.vue").then(m => m.default || m)
  },
  {
    name: "play",
    path: "/play",
    meta: playPZXwj3CFZkMeta || {},
    component: () => import("/usr/src/app/src/pages/play.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: indexYuIo5KMcuNMeta || {},
    component: () => import("/usr/src/app/src/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-privacy-policy",
    path: "/privacy/privacy-policy",
    meta: privacy_45policy19BsNAus8IMeta || {},
    component: () => import("/usr/src/app/src/pages/privacy/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "shop-slug",
    path: "/shop/:slug()",
    meta: _91slug_93xxGejvA0zGMeta || {},
    component: () => import("/usr/src/app/src/pages/shop/[slug].vue").then(m => m.default || m)
  },
  {
    name: "simplycodes-mobile-extension",
    path: "/simplycodes-mobile-extension",
    meta: simplycodes_45mobile_45extensionXauyZR8fyEMeta || {},
    component: () => import("/usr/src/app/src/pages/simplycodes-mobile-extension.vue").then(m => m.default || m)
  },
  {
    name: "smartshopper",
    path: "/smartshopper",
    meta: smartshoppero2W8Efc6qmMeta || {},
    component: () => import("/usr/src/app/src/pages/smartshopper.vue").then(m => m.default || m)
  },
  {
    name: "store-merchant",
    path: "/store/:merchant()",
    meta: _91merchant_93fN5PuGXriZMeta || {},
    component: () => import("/usr/src/app/src/pages/store/[merchant].vue").then(m => m.default || m),
    children: [
  {
    name: "store-merchant-coupons",
    path: "coupons",
    meta: couponsiKQJga7d1XMeta || {},
    component: () => import("/usr/src/app/src/pages/store/[merchant]/coupons.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "submit-coupon-merchant",
    path: "/submit-coupon/:merchant?",
    meta: _91_91merchant_93_93w8dYjFdTPvMeta || {},
    component: () => import("/usr/src/app/src/pages/submit-coupon/[[merchant]].vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsA1HF1pYOfSMeta || {},
    component: () => import("/usr/src/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "uninstall",
    path: "/uninstall",
    meta: uninstallJY9fJhHYptMeta || {},
    component: () => import("/usr/src/app/src/pages/uninstall.vue").then(m => m.default || m)
  },
  {
    name: "verify-code",
    path: "/verify-code",
    meta: verify_45codeku7f4YFtPYMeta || {},
    component: () => import("/usr/src/app/src/pages/verify-code.vue").then(m => m.default || m)
  }
]