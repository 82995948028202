/*
 * Utility classes related to URL/URI and query strings
 */

export function getSearchParams(params = <Record<string, any>>{}) {
  const filteredParams = Object.keys(params).reduce((agg, p) => {
    if (params[p] !== undefined && params[p] !== null)
      return { ...agg, [p]: params[p] };

    return agg;
  }, {});
  return new URLSearchParams(filteredParams).toString();
}

/**
 * getAllQueryStrings returns all query strings from a URL in a single object
 *
 * @param nextUrl String of the next URL with query params
 * @returns Record<string, string | string[]> All query strings in a single object
 */
export function getAllQueryStrings(nextUrl: string): Record<string, string | string[]> {
  const url = new URL(nextUrl);
  const searchParams = new URLSearchParams(url.search);
  const params: Record<string, string | string[]> = {};

  searchParams.forEach((value, key) => {
    if (params[key]) {
      if (Array.isArray(params[key])) {
        (params[key] as string[]).push(value);
      } else {
        params[key] = [params[key] as string, value];
      }
    } else {
      params[key] = value;
    }
  });

  return params;
}

/**
 * This util returns the post-login URL
 *
 * If a redirect URL is specified, it checks a redirect param to make sure it's valid to avoid
 * arbitrary redirects (https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html)
 *
 * A query paramter, has_logged_in=1, is attached for tracking purposes
 *
 * @param {string} redirect The redirect path we want to validate
 * @param {string} baseUrl The base URL for the redirect
 * @returns {string} Sanitized redirect URL
 */
export function getPostLoginRedirectURL(redirect: string, baseUrl: string): string {
  const isValidRedirect
    = redirect
    && !redirect.startsWith('/login')
    && !redirect.startsWith('/extension/login')
    // Must be an absolute path with character restrictions:
    && /^\/[\w\-?=&%./]+$/.test(redirect);

  if (!isValidRedirect) {
    return '';
  }

  const targetUrl = new URL(`${baseUrl}${redirect}`);

  targetUrl.searchParams.delete('has_logged_out');
  targetUrl.searchParams.set('has_logged_in', '1');
  return targetUrl.toString();
}

/**
 * This util returns the post-logout URL
 *
 * A query paramter, has_logged_out=1, is attached for tracking purposes
 *
 * @param {string} redirect The redirect path we want to validate
 * @param {string} baseUrl The base URL for the redirect
 * @returns {string} Sanitized redirect URL
 */
export function getPostLogoutRedirectURL(redirect: string, baseUrl: string): string {
  const targetUrl = new URL(`${baseUrl}${redirect || '/explore'}`);

  targetUrl.searchParams.delete('has_logged_in');
  targetUrl.searchParams.set('has_logged_out', '1');

  return targetUrl.toString();
}

export function getCookieDomain(host: string) {
  if (!host)
    return '.simplycodes.com';

  const match = host.match(/simplycodes(-dev|-qa|-local)?\.com/);
  return match ? `.${match[0]}` : '.simplycodes.com';
}
