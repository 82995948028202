import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45feature_45flag_45global from "/usr/src/app/src/middleware/01.featureFlag.global.ts";
import _02_45auth_45global from "/usr/src/app/src/middleware/02.auth.global.ts";
import _03_45redirects_45global from "/usr/src/app/src/middleware/03.redirects.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45feature_45flag_45global,
  _02_45auth_45global,
  _03_45redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  referrals: () => import("/usr/src/app/src/middleware/referrals.ts")
}